.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: AgrandirRegular;
  src: url(assets/Agrandir-Regular.otf);
}

@font-face {
  font-family: AgrandirThin;
  src: url(assets/Agrandir-Narrow.otf);
}

@font-face {
  font-family: CormorantGaramond;
  src: url(assets/CormorantGaramond-Bold.ttf);
}

@font-face {
  font-family: CGMedium;
  src: url(assets/CormorantGaramond-Medium.ttf);
}

@font-face {
  font-family: Balloon;
  src: url(assets/BALLOON1.woff);
}

a {
  text-decoration: none;
}